import React from 'react';
import classes from './Header.module.css';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
function Header() {
  return (
    <React.Fragment>
      <div className={classes['header-container']}>
        <Typography
          variant='h1'
          className={classes['page-title']}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontFamily: 'Dancing Script',
            color: '#fff',
            marginLeft: {
              xs: '0',
              lg: '0',
            },
            fontSize: {
              xs: '2rem',
              lg: '8rem',
            },
            whiteSpace: { xs: 'normal', lg: 'nowrap' },
          }}
        >
          <div>
            <span
              style={{
                fontFamily: 'Dancing Script',
              }}
            >
              A Little
            </span>
          </div>
          <div>
            <Typography
              variant='caption'
              sx={{
                fontFamily: 'Dancing Script',
                fontSize: 'inherit',
                position: 'relative',
              }}
            >
              of
            </Typography>{' '}
          </div>
          <div>
            <span
              style={{
                fontFamily: 'Dancing Script',
              }}
            >
              Bakeshop
            </span>
          </div>
        </Typography>
      </div>
    </React.Fragment>
  );
}

export default Header;
