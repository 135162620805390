import React from 'react';
import Menu from './Menu';
import Welcome from './Welcome';

function Body() {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Welcome />
      <Menu />
    </div>
  );
}

export default Body;
