import React from 'react';
import Cupcakes from '../Categories/Cupcakes/Cupcakes';
import Cookies from '../Categories/Cookies/Cookies';
import Donuts from '../Categories/Donuts/Donuts';
import Scones from '../Categories/Scones/Scones';
import CupcakeCakes from '../Categories/CupcakeCakes/CupcakeCakes';
import Icing from '../Categories/Icing/Icing';
import { Box } from '@mui/material';
function Menu() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: {
          xs: 'center',
          lg: 'flex-end',
        },
        marginLeft: { xs: '2rem', lg: '0' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          gap: {
            lg: '15rem',
          },
          alignItems: { lg: 'flex-start' },
          marginLeft: { lg: '-5rem' },
          marginTop: { lg: '1rem' },
        }}
      >
        <Box
          sx={{
            display: { lg: 'flex' },
            flexDirection: { lg: 'column' },
          }}
        >
          <Cookies />
          <Donuts />
          <Scones />
        </Box>
        <Box
          sx={{
            display: { lg: 'flex' },
            alignItems: { lg: 'center' },
            gap: { lg: '2rem' },
            marginTop: { xs: '5rem', lg: '0' },
          }}
        >
          {' '}
          <Cupcakes />
          <Icing />
        </Box>
      </Box>
      <CupcakeCakes />
    </Box>
  );
}

export default Menu;
