import React from 'react';
import { Box, Typography } from '@mui/material';
function Scones() {
  return (
    <Box style={{ marginTop: '2rem' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h4"
          color="primary"
          sx={{
            fontWeight: '500',
            marginLeft: { xs: '3rem', lg: '1rem' },
          }}
        >
          Scones
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500',
            marginTop: '0.2rem',
            marginLeft: { xs: '1rem', lg: '-.5rem' },
          }}
          color="primary"
        >
          $6/half dozen $12/dozen
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', lg: '' },
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: { xs: '100%', lg: '' },
          marginLeft: { xs: '0', lg: '0' },
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Chocolate Chip
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Pumpkin Butter Scotch
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Lemon
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Cinnamon
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Cranberry Orange
        </Typography>
      </Box>
    </Box>
  );
}

export default Scones;
