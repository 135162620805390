import React from 'react';
import { Box, Typography } from '@mui/material';

function CupcakeCakes() {
  return (
    <Box
      sx={{
        alignSelf: 'flex-start',
        marginLeft: { xs: '', lg: '28rem' },
        marginTop: { xs: '2rem', lg: '-20rem' },
      }}
    >
      <Typography
        variant="h4"
        color="primary"
        sx={{
          display: 'flex',
          marginRight: '0.4rem',
          flexDirection: { xs: 'column', lg: '' },
          alignItems: { xs: 'center', lg: '' },
          marginLeft: { xs: '0rem', lg: '' },
        }}
      >
        Cupcake{' '}
        <Typography variant="span" sx={{ fontFamily: 'Garamond' }}>
          Cakes
        </Typography>
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: '500',
          marginTop: '0.2rem',
          marginLeft: { xs: '0.7rem', lg: '-1rem' },
          marginRight: { xs: '0rem', lg: '-4rem' },
        }}
        color="primary"
        fontFamily="Helvetica Neue"
      >
        $25/dozen $45/two dozen
      </Typography>
    </Box>
  );
}

export default CupcakeCakes;
