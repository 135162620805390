import React from 'react';
import { Box, Typography } from '@mui/material';
function Donuts() {
  return (
    <Box
      sx={{
        marginTop: { xs: '2rem', lg: '2rem' },
        display: { xs: 'flex', lg: '' },
        flexDirection: {
          xs: 'column',
        },
      }}
    >
      {' '}
      <Box
        sx={{
          display: { lg: 'flex' },
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h4"
          color="primary"
          sx={{
            fontWeight: '500',
            marginLeft: { xs: '3rem', lg: '2rem' },
          }}
        >
          Donuts
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500',
            marginTop: '0.2rem',
            marginLeft: { xs: '1rem', lg: '0rem' },
          }}
          color="primary"
        >
          $6/half dozen $12/dozen
        </Typography>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'flex',
            lg: '',
          },
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: { xs: '100%', lg: '' },
          marginLeft: { xs: '0rem', lg: '0' },
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Chocolate Peanut Butter
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Pumpkin
        </Typography>
      </Box>
    </Box>
  );
}

export default Donuts;
