import React from 'react';
import { Typography, Box } from '@mui/material';

function Cupcakes() {
  return (
    <Box
      sx={{
        display: { xs: 'flex', lg: '' },
        flexDirection: {
          xs: 'column',
          lg: '',
        },
        gap: '0rem',
        alignItems: 'flex-start',
      }}
    >
      <Typography
        variant="h4"
        color="primary"
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
          },
          alignItems: {
            xs: 'center',
            lg: 'center',
          },
          marginTop: {
            xs: '-3rem',
            lg: '0',
          },
          marginLeft: {
            xs: '0rem',
            lg: '0',
          },
        }}
      >
        Cupcakes{' '}
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: '500',
            marginTop: '0.2rem',
            marginLeft: '0.1rem',
          }}
        >
          $10/half dozen $16/dozen
        </Typography>
      </Typography>
      <Box
        sx={{
          display: { xs: 'flex', lg: 'block' },
          flexDirection: { xs: 'column', lg: 'row' },
          width: { xs: '100%', lg: 'auto' },
          alignItems: {
            xs: 'flex-start',
            lg: 'center',
          },
          marginLeft: {
            xs: '',
          },
          marginTop: { xs: '1rem' },
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Chocolate
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Vanilla
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Confetti
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Pumpkin
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Strawberry
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Lemon
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Buckeye
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Pina Colada
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Snicker Doodle
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Mexican Hot Chocolate
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Cookies n Cream
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Red Velvet
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: 'Lato' }}
          color="secondary"
        >
          Mint Chocolate
        </Typography>
      </Box>
    </Box>
  );
}

export default Cupcakes;
