import React from 'react';
import { Box, Typography } from '@mui/material';
function Cookies() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'flex-start', lg: 'flex-start' },
        marginTop: { xs: '1.5rem', lg: '0' },
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex' },
          flexDirection: { xs: 'column' },
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography
          variant='h4'
          color='primary'
          sx={{
            marginLeft: { xs: '-2rem', lg: '-25px' },
          }}
        >
          Cookies
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: '500',
            marginLeft: { xs: '-3rem', lg: '-3rem' },
          }}
          color='primary'
        >
          $6/half dozen $12/dozen
        </Typography>
      </Box>
      <Box sx={{ marginTop: '1rem' }}>
        <Typography
          variant='subtitle1'
          style={{ fontFamily: 'Lato' }}
          color='secondary'
        >
          Chocolate Chip
        </Typography>
        <Typography
          variant='subtitle1'
          style={{ fontFamily: 'Lato' }}
          color='secondary'
        >
          Molasses
        </Typography>
        <Typography
          variant='subtitle1'
          style={{ fontFamily: 'Lato' }}
          color='secondary'
        >
          Oatmeal Raisin
        </Typography>
        <Typography
          variant='subtitle1'
          style={{ fontFamily: 'Lato' }}
          color='secondary'
        >
          Chocolate iced chocolate
        </Typography>
        <Typography
          variant='subtitle1'
          style={{ fontFamily: 'Lato' }}
          color='secondary'
        >
          Snicker Doodle
        </Typography>
        <Typography
          variant='subtitle1'
          style={{ fontFamily: 'Lato' }}
          color='secondary'
        >
          Peanut Butter
        </Typography>
        <Typography
          variant='subtitle1'
          style={{ fontFamily: 'Lato' }}
          color='secondary'
        >
          Iced Sugar{' '}
          <span style={{ color: '#602e02' }}>- $10/half dozen $15/dozen</span>
        </Typography>
      </Box>
    </Box>
  );
}

export default Cookies;
