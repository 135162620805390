import React from 'react';
import { Typography, Box } from '@mui/material';
function Icing() {
  return (
    <Box
      sx={{
        display: {
          xs: 'flex',
          lg: 'flex',
        },
        flexDirection: { xs: 'column', lg: 'column' },
        alignItems: { xs: 'flex-start', lg: 'center' },
        width: { xs: '100%', lg: 'auto' },
        marginTop: { xs: '1rem', lg: '0' },
      }}
    >
      <Typography
        variant='h5'
        sx={{
          marginTop: { xs: '1rem', lg: '1rem' },
          marginLeft: { xs: '4rem', lg: '0' },
          textTransform: 'uppercase',
          fontFamily: 'Garamond',
          fontSize: { xs: '2rem', lg: '1.5rem' },
        }}
        color='primary'
      >
        {' '}
        Icing
      </Typography>

      <Typography
        variant='subtitle1'
        style={{ fontFamily: 'Lato' }}
        color='secondary'
      >
        Vanilla Buttercream
      </Typography>
      <Typography
        variant='subtitle1'
        style={{ fontFamily: 'Lato' }}
        color='secondary'
      >
        Chocolate
      </Typography>
      <Typography
        variant='subtitle1'
        style={{ fontFamily: 'Lato' }}
        color='secondary'
      >
        Cream Cheese
      </Typography>
      <Typography
        variant='subtitle1'
        style={{ fontFamily: 'Helvetica' }}
        color='secondary'
      >
        Lemon
      </Typography>
      <Typography
        variant='subtitle1'
        style={{ fontFamily: 'Lato' }}
        color='secondary'
      >
        Strawberry
      </Typography>
      <Typography
        variant='subtitle1'
        style={{ fontFamily: 'Lato' }}
        color='secondary'
      >
        Marshmallow
      </Typography>
    </Box>
  );
}

export default Icing;
