import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function Theme(props) {
  const theme = createTheme({
    typography: {
      fontFamily: 'Helvetica Neue',
      h4: {
        textTransform: 'uppercase',
        fontFamily: 'Garamond',
      },
    },
    palette: {
      primary: {
        main: '#602e02;',
      },
      secondary: {
        main: '#636363;',
      },
    },
  });
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export default Theme;
