import React from 'react';
import { Box, Typography } from '@mui/material';
function Welcome() {
  return (
    <Typography
      variant='h5'
      color='primary'
      sx={{
        textTransform: 'uppercase',
        fontWeight: '300',
        fontFamily: 'Garamond',
        fontSize: { xs: '3rem', lg: '7rem' },
        marginTop: { lg: '3rem' },
      }}
    >
      Menu
    </Typography>
  );
}

export default Welcome;
